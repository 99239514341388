@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@500;700&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500;600&family=Outfit:wght@500;700&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@600&family=Source+Sans+Pro:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

/* :root {

} */
.MuiInputBase-input::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #6b8599;

  /* background-color: #ffffff;
  border-radius: 10px;
  padding: 2px; */
}

.css-1fy9pjd-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px;
  font-family: "Poppins";
}

.css-1vsrkt8-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 13px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15px;
}

.css-1f2kvjf-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 16px;
  color: #929eae;
  font-family: "Poppins";
}

.App {
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
}

*:focus {
  outline: none;
}

body {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.PrivateTabIndicator-root-1.PrivateTabIndicator-colorSecondary-3.MuiTabs-indicator {
  background-color: #3860de;
}
.PrivateTabIndicator-root-5.PrivateTabIndicator-colorSecondary-7.MuiTabs-indicator {
  background-color: #3860de;
}
.MuiTab-wrapper {
  font-size: 14px;
  color: #bcc5d6;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault.css-i4bv87-MuiSvgIcon-root {
  color: #bcc5d6;
  border-radius: none;
  -webkit-border-radius: none;
  -moz-border-radius: none;
  -ms-border-radius: none;
  -o-border-radius: none;
}
.css-nnid7-MuiSlider-valueLabel {
  background-color: #1976d2 !important ;
}
.MuiBox-root.css-iuxnrt {
  margin-top: 5px;
}
@media (max-width: 768px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 52%;
  }
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

::-webkit-scrollbar {
  display: block;
}
::-webkit-scrollbar {
  width: 07px;
}

::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(59, 52, 134, 0.38);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

.transactionTable {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: initial;
  margin: 0;
}

.otpInput[type="number"] {
  -moz-appearance: textfield;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder {
  color: #adb8cc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: initial;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.voucher-variations .MuiBox-root {
  width: 140px !important;
}
#mui-component-select-MeasurementUnit {
  padding: 0 !important;
}
