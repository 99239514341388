/* General chart container */
.chart-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background-color: #fff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title style */
.chart-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Tabs for Sales and Purchases */
.tab-container {
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  border: 1px solid #ddd;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #2261a2;
  color: #fff;
  border: 1px solid #2261a2;
}

.tab-button:hover {
  background-color: #2261a2;
  color: #fff;
}

/* Dropdown container */
.dropdown-container {
  margin-bottom: 30px;
}

.timeline-dropdown {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  width: 200px;
  font-family: "Poppins";
}

/* Chart wrapper for better spacing */
.chart-wrapper {
  margin-top: 40px;
}

/* Styling for Y-axis labels (number formatting) */
.y-axis-labels {
  font-size: 14px;
  font-weight: 500;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .chart-container {
    width: 90%;
  }

  .timeline-dropdown {
    width: 100%;
  }

  .tab-button {
    padding: 8px 16px;
  }

  .chart-title {
    font-size: 20px;
  }
}
